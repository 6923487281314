<template>
  <transition name="slide-right" :appear="true">
    <div class="view-container">
      <div class="analytic-form display--flex">
        <div class="card w-full">
          <div class="card-header card-header-divider">
            <div class="card-title">Аналитика</div>
          </div>
          <div class="card-body">
            <div class="analytic-form__params"></div>

            <div class="analytic-form__report-type justify-content-start grid">
              <div v-for="analytic in analytics" :key="analytic.id">
                <router-link
                    v-if="isSuperAdmin || analytic.name !== 'Данные по тестированиям'"
                    :key="analytic.id"
                    :to="analytic.link"
                    class="analytic-link"
                >
                  <div class="link-body">
                    <div class="icon">
                      <i :class="`fad ${analytic.icon}`"></i>
                    </div>
                    <div class="link-info">
                      <div class="name">{{ analytic.name }}</div>
                      <div class="description">{{ analytic.description }}</div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from "@vue/reactivity";
import BaseReportParametres from "../../feature/analytics/report-parameters/BaseReportParametres.vue";
import { computed, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import BaseReport from "../../feature/analytics/reports/BaseReport.vue";
import GroupReportParametres from "../../feature/analytics/report-parameters/GroupReportParametres.vue";
import GroupReport from "../../feature/analytics/reports/GroupReport.vue";
import GeneralDynamicParametres from "../../feature/analytics/report-parameters/GeneralDynamicParametres.vue";
import ReferenceReportParametres from "../../feature/analytics/report-parameters/ReferenceReportParametres.vue";
import ReferenceUsersReportParametres from "../../feature/analytics/report-parameters/ReferenceUsersReportParametres.vue";
import RepeatedReportParametres from "../../feature/analytics/report-parameters/RepeatedReportParametres.vue";
import DemographicReportParametres from "../../feature/analytics/report-parameters/DemographicReportParametres.vue";
import DemographicReport from "../../feature/analytics/reports/DemographicReport.vue";
import ReferenceUsersReport from "../../feature/analytics/reports/ReferenceUsersReport.vue";
import useCheckRights from "../../mixins/useCheckRights";
import analyticsReports from "../../mixins/dictionaries/analyticsReports";
import CustomSelect2 from "../../components/Forms/Fields/CustomSelect2.vue";

export default {
  components: {
    CustomSelect2,
    BaseReportParametres,
    BaseReport,
    GroupReportParametres,
    GroupReport,
    GeneralDynamicParametres,
    ReferenceReportParametres,
    ReferenceUsersReportParametres,
    RepeatedReportParametres,
    DemographicReportParametres,
    DemographicReport,
    ReferenceUsersReport,
  },
  name: "analytic-form",
  setup() {
    const store = useStore(),
      { getRight } = useCheckRights();
    const reportType = ref(null),
      reportRequest = ref(false),
      groupId = ref(null);
    const winWidth = computed(() => store.state.width),
      reportData = computed(() => store.state.analytics.reportData),
      reportReservedData = computed(
        () => store.state.analytics.reportReservedData
      );
    const analytics = analyticsReports;

    const print = () => {
      window.print();
    };

    const isSuperAdmin = computed(() => getRight('superadmin'));

    watch(reportType, () => {
      reportRequest.value = false;
      store.commit("analytics/setReportData", null);
    });

    return {
      reportType,
      reportRequest,
      print,
      winWidth,
      reportData,
      reportReservedData,
      getRight,
      groupId,
      analytics,
      isSuperAdmin
    };
  },
};
</script>

<style lang="scss" scoped>
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.2s ease-out;
}

.slide-right-leave-to,
.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-1000px);
}
.analytic-form {
  &__options,
  &__report-type {
    margin: 20px auto;
    .btn {
      margin-right: 10px;
    }
  }
  &__report-type {
    grid-template-columns: 1fr 1fr;
  }

  &__description {
    background-color: var(--cream-color);
    width: 100%;
    padding: 20px;
    height: 220px;
    text-overflow: ellipsis;
    margin-left: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &__table {
    margin-top: 40px;
  }

  &__report-title {
    font-size: 1.1rem;
    font-weight: 300;
    color: #3d3d3d;
    padding: 24px 0 14px;
    margin: 0 25px;
    border-bottom: 0 solid rgba(0, 0, 0, 0.125);
    line-height: 1.738;
  }

  @media (max-width: 1400px) {
    &__report-type {
      grid-template-columns: 1fr;
    }

    &__description {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}
.analytic-link {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid var(--cream-color);
  justify-content: center;

  .link-body {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .icon {
    font-size: 55px;
    margin-right: 18px;
    width: 60px;
    display: flex;
    justify-content: center;
    color: var(--icons-color);
  }

  .name {
    font-size: 18px;
    font-weight: 500;
  }

  .description {
    font-size: 15px;
  }

  &:hover {
    .icon {
      color: var(--main-color);
    }
  }

  @media (max-width: 1024px) {
    padding: 17px;
    .link-body {
      min-width: 100%;
    }

    .icon {
      font-size: 43px;
      margin-right: 15px;
      min-width: 48px;
      max-width: 48px;
    }

    .name {
      font-size: 16px;
    }
  }
}
</style>
